import type { CreateOrEditEmailTemplate, EmailTemplateDto, GetEmailTemplatesInput, SendTestEmailRequest } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatesService {
  apiName = 'Default';
  

  deleteTemplateById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/app/email-templates',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  exportByInput = (input: GetEmailTemplatesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/email-templates/export',
      params: { name: input.name, description: input.description, subject: input.subject, filterText: input.filterText, columnHeaders: input.columnHeaders, selectedRowIds: input.selectedRowIds, downloadToken: input.downloadToken, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetEmailTemplatesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<EmailTemplateDto>>({
      method: 'GET',
      url: '/api/app/email-templates',
      params: { name: input.name, description: input.description, subject: input.subject, filterText: input.filterText, columnHeaders: input.columnHeaders, selectedRowIds: input.selectedRowIds, downloadToken: input.downloadToken, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getTemplateForEditById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreateOrEditEmailTemplate>({
      method: 'GET',
      url: '/api/app/email-templates/get-template',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  saveEmailTemplateByEmailTemplate = (emailTemplate: CreateOrEditEmailTemplate, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/email-templates',
      body: emailTemplate,
    },
    { apiName: this.apiName,...config });
  

  sendTestEmailByRequest = (request: SendTestEmailRequest, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/email-templates/send-test-email',
      body: request,
    },
    { apiName: this.apiName,...config });
  

  uploadEmailTemplateByStream = (stream: FormData, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CreateOrEditEmailTemplate>({
      method: 'POST',
      url: '/api/app/email-templates/upload',
      body: stream,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
